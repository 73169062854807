import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { Link } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import CursorPanel from '~components/CursorPanel'
import Image from '~components/Image'

const ProjectIndexTile = ({ className, i, project, tiles, isMobile }) => {

	const aspectRatios = [
		{ 
			ratio: isMobile ? 1.331 : 1.331,
		},
		{ 
			ratio: isMobile ? 1.331 : 1.0282,
		},
		{ 
			ratio: isMobile ? 1.331 : 1.628,
		},
		{ 
			ratio: isMobile ? 1.331 : 0.7942,
		},
		{ 
			ratio: isMobile ? 1.331 : 1.628,
		}
	]

	const [loading, setLoading] = useState(project?.thumbnailType !== 'image')

	return (
		<Wrap 
			className={className} 
		>
			<StyledLink to={resolveLink(project)} loading={loading}>
				<Hover color={tiles[i]?.cursorColor} cursorMessage={project.cursorMessage ? project.cursorMessage : 'View Project'}/>
				<Size aspectRatio={1.49}>
					<>
						{project?.thumbnailType === 'image' ?
							<StyledImage 
								cover 
								image={project?.thumbnail?.landscape} 
								// aspectRatio={ isMobile ? 1.331 : 1.331}
								aspectRatio={1.49}
							/>
							:
							<Video 
								src={project?.thumbnailVideo?.landscape?.video?.asset?.url } 
								loop 
								muted 
								playsInline={true} 
								autoPlay={true}
								onLoadedData={() => setLoading(false)}
							/>
						}
					</>
				</Size>
				<Titles>
					<Title>{project?.title} — {project?.sectors[0]?.title}</Title>
				</Titles>
			</StyledLink>
		</Wrap>
	)
}

const Wrap = styled.div`
	background: #F7F7FC;
	overflow: hidden;
	position: relative;
	/* height: 0; */
`
const Size = styled.div`
	padding-bottom: ${props => (1 / props.aspectRatio) * 100 + '%' };
	height: 0;
	position: relative;
`
const Hover = styled(CursorPanel)`
	left: 0;
	right: 0;
	width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
`
const StyledImage = styled(Image)`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	img {
		object-fit: cover;
	}
`
const Video = styled.video`
	object-fit: cover;
	object-position: center;
	width: calc(100% + 4px);
	height: 100%;
	position: absolute;
	left: -2px;
	top: 0;
`

const StyledLink = styled(Link)`
	opacity: ${props => props.loading ? 0 : 1};
	transition: opacity 0.5s;
`
const Titles = styled.div`
	display: flex;
	padding-top: 18px;
`
const Title = styled.div`
	
`
const Sector = styled.div`
	
`
ProjectIndexTile.propTypes = {
	className: PropTypes.string,
	i: PropTypes.number,
	project: PropTypes.object, 
	tiles: PropTypes.array, 
	isMobile: PropTypes.bool,
}

export default ProjectIndexTile